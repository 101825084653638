import React from "react";
import { Link } from "gatsby";
import {
  RealmAppProvider,
  APP_ID,
  OnboardingRedirector,
} from "../../../components/realm_app";
import WelcomeHeader from "../../../components/welcome_header";

const EmailConfirmedPage = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <WelcomeHeader link={true} suffix="Facilitator Portal" />
      <div>
        A verification email has been sent to your inbox. Please use it to
        complete registration.
      </div>
      <br />
      <Link to="/facilitator/">Go Home</Link>
    </div>
  );
};

export default ({ location }) => {
  return (
    <RealmAppProvider appId={APP_ID}>
      {/* <OnboardingRedirector location={location}> */}
      <EmailConfirmedPage />
      {/* </OnboardingRedirector> */}
    </RealmAppProvider>
  );
};
